import PropTypes from "prop-types";

const Hero = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch bg-white overflow-hidden flex flex-row items-start justify-end py-0 px-[65px] box-border max-w-full text-justify text-41xl text-black font-prosto-one mq1225:pl-8 mq1225:pr-8 mq1225:box-border ${className}`}
    >
      <div className="w-[1635px] flex flex-row flex-wrap items-start justify-start py-0 pr-0.5 pl-0 box-border gap-[60px_58px] max-w-full mq750:gap-[29px]">
        <div className="flex-1 flex flex-col items-start justify-start pt-[43.3px] px-0 pb-0 box-border min-w-[512px] max-w-full mq750:pt-7 mq750:box-border mq750:min-w-full">
          <div className="self-stretch flex flex-col items-start justify-start pt-[26.1px] px-0 pb-[26px] box-border gap-[52.2px] min-w-[340px] max-w-full mq750:pt-5 mq750:pb-5 mq750:box-border mq450:gap-[26px]">
            <h1 className="m-0 self-stretch relative text-inherit font-normal font-inherit inline-block min-w-[320px] mq750:text-29xl mq450:text-17xl">
              Against the front line of engineering
            </h1>
            <button className="cursor-pointer pt-[21px] pb-[22px] pr-[42px] pl-[52px] bg-white rounded-[52.15px] box-border flex flex-row items-start justify-start gap-[32.1px] max-w-full border-[1.4px] border-solid border-gray-200 mq450:flex-wrap mq450:gap-[16px] mq450:pl-5 mq450:pr-5 mq450:box-border">
              <div className="h-[87.8px] w-[333.5px] relative rounded-[52.15px] bg-white box-border hidden max-w-full border-[1.4px] border-solid border-gray-200" />
              <div className="relative text-8xl-4 font-medium font-poppins text-black text-left z-[1] mq450:text-3xl">
                Scroll down
              </div>
              <div className="flex flex-col items-start justify-start pt-[20.6px] px-0 pb-0">
                <img
                  className="w-[44.6px] h-[15.8px] relative rounded-[4.12px] z-[1]"
                  alt=""
                  src="/vector-2509.svg"
                />
              </div>
            </button>
            <div className="w-[457px] h-[158px] relative mix-blend-normal max-w-full flex items-center justify-center">
              <img
                className="w-full h-full mix-blend-normal object-contain absolute left-[4px] top-[7px] [transform:scale(1.133)]"
                loading="lazy"
                alt=""
                src="/group-1321314344.svg"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center justify-center min-w-[512px] max-w-full mq750:min-w-full">
          <img
            className="w-[682px] relative max-h-full object-cover max-w-full"
            loading="lazy"
            alt=""
            src="/rectangle-18210@2x.png"
          />
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
};

export default Hero;
