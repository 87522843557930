import PropTypes from "prop-types";

const MyDesignPreview = ({
  className = "",
  imageBackgrounds,
  mobileDesign,
  profilePictures,
}) => {
  return (
    <div
      className={`flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[33px] box-border relative gap-[19.2px] min-w-[351px] max-w-full text-left text-xl text-black font-poppins ${className}`}
    >
      <div className="w-full h-full absolute !m-[0] top-[0px] bottom-[0px] left-[0px] shadow-[11px_14px_73px_rgba(172,_192,_222,_0.25)] rounded-2xl bg-white" />
      <img
        className="self-stretch h-[293.8px] relative rounded-xl max-w-full overflow-hidden shrink-0 object-cover z-[1]"
        loading="lazy"
        alt=""
        src={imageBackgrounds}
      />
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-[30px]">
        <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
          <div className="relative text-7xl font-medium z-[1] mq450:text-2xl">
            {mobileDesign}
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-0.5 text-right text-dimgray">
            <div className="flex-1 flex flex-row items-start justify-between gap-[20px] z-[1] mq450:flex-wrap">
              <img
                className="h-16 w-[62.4px] relative rounded-[50%] object-cover"
                loading="lazy"
                alt=""
                src={profilePictures}
              />
              <div className="w-[169px] flex flex-col items-start justify-start pt-[17px] px-0 pb-0 box-border">
                <div className="self-stretch relative font-medium mq450:text-base">
                  By Saksham
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch shadow-[0px_13px_46px_rgba(211,_175,_255,_0.62)] flex flex-row items-start justify-between py-[11px] pr-1 pl-6 relative gap-[20px] z-[1] text-white mq450:flex-wrap">
            <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-19xl bg-darkorchid box-border border-[1px] border-solid border-gray-200" />
            <div className="relative font-medium inline-block min-w-[113.5px] z-[1] mq450:text-base">
              Full Detials
            </div>
            <div className="flex flex-col items-start justify-start pt-[15px] px-0 pb-0">
              <img
                className="w-[34.2px] h-[11.5px] relative rounded-10xs z-[1]"
                alt=""
                src="/vector-2509-1.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MyDesignPreview.propTypes = {
  className: PropTypes.string,
  imageBackgrounds: PropTypes.string,
  mobileDesign: PropTypes.string,
  profilePictures: PropTypes.string,
};

export default MyDesignPreview;
