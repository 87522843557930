import PropTypes from "prop-types";

const Footer = ({ className = "" }) => {
  return (
    <footer
      className={`self-stretch bg-gray-300 flex flex-row items-start justify-between pt-[63px] pb-[59.5px] pr-[275px] pl-[250px] box-border max-w-full gap-[20px] text-left text-11xl text-gray-100 font-poppins mq450:pl-[62px] mq450:pr-[68px] mq450:box-border mq1050:pl-[125px] mq1050:pr-[137px] mq1050:box-border mq1225:flex-wrap ${className}`}
    >
      <div className="h-[332px] w-[1805px] relative bg-gray-300 hidden max-w-full" />
      <div className="w-[418px] flex flex-col items-start justify-start gap-[32px] max-w-full mq450:gap-[16px]">
        <div className="flex flex-row items-start justify-start gap-[11px] max-w-full mq450:flex-wrap">
          <div className="flex flex-col items-start justify-start pt-[1.5px] px-0 pb-0">
            <h3 className="m-0 relative text-inherit font-semibold font-inherit z-[1] mq750:text-5xl mq450:text-lg">
              Shavista Infotech
            </h3>
          </div>
          <img
            className="self-stretch w-[58px] relative max-h-full object-cover min-h-[48px] z-[1]"
            loading="lazy"
            alt=""
            src="/logo-2@2x.png"
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[18px] text-17xl text-white font-prosto-one">
          <h2 className="m-0 self-stretch relative text-inherit font-normal font-inherit z-[1] mq750:text-10xl mq450:text-3xl">
            Contact Us
          </h2>
          <div className="self-stretch relative text-xl font-poppins text-darkturquoise z-[1] mq450:text-base">
            Email: info@shavistainfotech.com
          </div>
        </div>
      </div>
      <div className="w-[257.1px] flex flex-col items-start justify-start pt-2.5 px-0 pb-0 box-border">
        <div className="self-stretch h-[199.5px] flex flex-row flex-wrap items-start justify-start relative z-[1]">
          <div className="h-[121.5px] w-[257px] absolute !m-[0] top-[0px] left-[0px]">
            <div className="absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[163.5px] flex items-center justify-center">
              <img
                className="h-full w-full object-contain absolute left-[-6px] top-[1px] [transform:scale(1.087)]"
                alt=""
                src="/vector-2517.svg"
              />
            </div>
            <div className="absolute h-full top-[0px] bottom-[0px] left-[93.5px] max-h-full w-[163.5px] z-[1] flex items-center justify-center">
              <img
                className="h-full w-full z-[1] object-contain absolute left-[-6px] top-[1px] [transform:scale(1.087)]"
                loading="lazy"
                alt=""
                src="/vector-2518.svg"
              />
            </div>
          </div>
          <div className="w-[143.8px] !m-[0] absolute top-[155.5px] left-[0px] flex flex-row items-start justify-between gap-[20px]">
            <div className="flex flex-col items-start justify-start pt-[3.4px] px-0 pb-0">
              <img
                className="w-[37.2px] h-[37.2px] relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/faexternallinksquare.svg"
              />
            </div>
            <img
              className="h-11 w-11 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/akariconsfacebookfill.svg"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
