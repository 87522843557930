import PropTypes from "prop-types";

const FrameComponent1 = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[53.5px] box-border max-w-full text-left text-21xl text-black font-prosto-one mq750:pb-[35px] mq750:box-border ${className}`}
    >
      <div className="flex-1 bg-white overflow-hidden flex flex-row items-start justify-start py-0 px-[120px] box-border max-w-full mq450:pl-[30px] mq450:pr-[30px] mq450:box-border mq1050:pl-[60px] mq1050:pr-[60px] mq1050:box-border">
        <div className="flex-1 flex flex-row flex-wrap items-start justify-start py-0 pr-0.5 pl-0 box-border gap-[91px_65px] max-w-full mq750:gap-[32px] mq450:gap-[16px]">
          <img
            className="w-[633px] relative max-h-full object-cover max-w-full"
            loading="lazy"
            alt=""
            src="/group-1321314597-1@2x.png"
          />
          <div className="flex-1 flex flex-col items-start justify-start pt-[72px] px-0 pb-0 box-border min-w-[346px] max-w-full mq750:pt-[47px] mq750:box-border">
            <div className="self-stretch overflow-hidden flex flex-col items-start justify-start gap-[73px] max-w-full mq450:gap-[18px] mq1050:gap-[36px]">
              <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
                <div className="flex flex-col items-start justify-start gap-[20px]">
                  <h1 className="m-0 relative text-inherit font-normal font-inherit mq750:text-13xl mq450:text-5xl">
                    Our Proccess
                  </h1>
                  <div className="flex flex-row items-start justify-start py-0 px-[61px] text-xl text-gray-400 font-poppins mq450:pl-5 mq450:pr-5 mq450:box-border">
                    <div className="relative mq450:text-base">
                      The way we work.
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[0px_26px] min-h-[322px] max-w-full text-7xl font-poppins">
                <div className="flex-1 flex flex-col items-center justify-between py-2.5 px-0 box-border min-w-[320px] max-w-[600px] min-h-[161px] mq750:max-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                    <div className="self-stretch relative font-medium mq450:text-2xl">
                      Customer in mind
                    </div>
                    <div className="w-full h-24 relative text-base text-dimgray text-justify inline-block min-w-[320px] max-w-[600px] mq750:max-w-full">
                      Our constant contact with the customer allows us to
                      deliver every requirement to an exceptional level.
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-between py-2.5 px-0 box-border min-w-[320px] max-w-[600px] min-h-[161px] mq750:max-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                    <div className="self-stretch relative font-medium mq450:text-2xl">
                      In Depth
                    </div>
                    <div className="w-full h-24 relative text-base text-dimgray text-justify inline-block min-w-[320px] max-w-[600px] mq750:max-w-full">
                      We break down problems in depth and discuss the solution’s
                      every aspect. Ensuring longevity, robustness, performance
                      and efficiency.
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-between py-2.5 px-0 box-border min-w-[320px] max-w-[600px] min-h-[161px] mq750:max-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                    <div className="self-stretch relative font-medium mq450:text-2xl">
                      High quality
                    </div>
                    <div className="w-full relative text-base text-dimgray text-justify inline-block min-w-[320px] max-w-[600px] mq750:max-w-full">
                      Our teams are highly focused on delivering products to the
                      highest form of quality. Following set engineering
                      processes to build the best solutions.
                    </div>
                  </div>
                </div>
                <div className="flex-1 overflow-hidden flex flex-col items-center justify-between py-2.5 px-0 box-border min-w-[320px] max-w-[600px] min-h-[161px] mq750:max-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                    <div className="self-stretch relative font-medium mq450:text-2xl">
                      Teamwork
                    </div>
                    <div className="w-full h-24 relative text-base text-dimgray text-justify inline-block min-w-[320px] max-w-[600px] mq750:max-w-full">
                      We have a team that is always supporting each other, and
                      we consider our customers the same. Together, we can
                      achieve greatness.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
