import Navigation1 from "../components/Navigation1";
import Hero from "../components/Hero";
import FrameComponent2 from "../components/FrameComponent2";
import FrameComponent1 from "../components/FrameComponent1";
import FrameComponent from "../components/FrameComponent";
import Footer from "../components/Footer";

const ShavistaMain = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <Navigation1 />
      <Hero />
      <FrameComponent2 />
      <section className="self-stretch bg-gray-300 flex flex-row flex-wrap items-start justify-center py-11 pr-[49px] pl-[50px] box-border max-w-full text-center text-31xl text-gray-100 font-prosto-one mq1050:pl-[25px] mq1050:pr-6 mq1050:box-border">
        <div className="flex-1 flex flex-col items-start justify-start gap-[8px] min-w-[225px] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-px box-border max-w-full">
            <h1 className="m-0 flex-1 relative text-inherit font-normal font-inherit inline-block max-w-full mq750:text-21xl mq450:text-11xl">
              Marketing
            </h1>
          </div>
          <div className="self-stretch relative text-xl font-poppins text-darkturquoise mq450:text-base">
            Marketing Tools
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[8px] min-w-[225px] max-w-full ml-[-1.4px]">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-px box-border max-w-full">
            <h1 className="m-0 flex-1 relative text-inherit font-normal font-inherit inline-block max-w-full mq750:text-21xl mq450:text-11xl">
              A.I.
            </h1>
          </div>
          <div className="self-stretch relative text-xl font-poppins text-darkturquoise mq450:text-base">
            Content Generators
          </div>
        </div>
        <div className="flex-1 flex flex-col items-end justify-start gap-[8px] min-w-[225px] max-w-full z-[1] ml-[-1.4px]">
          <div className="self-stretch flex flex-row items-start justify-center py-0 pr-5 pl-[23px]">
            <h1 className="m-0 w-[204.4px] relative text-inherit font-normal font-inherit inline-block shrink-0 mq750:text-21xl mq450:text-11xl">
              Data
            </h1>
          </div>
          <div className="self-stretch relative text-xl font-poppins text-darkturquoise mq450:text-base">{`Reward Systems for Retail `}</div>
        </div>
        <div className="flex-1 flex flex-col items-end justify-start gap-[8px] min-w-[225px] max-w-full ml-[-1.4px]">
          <div className="self-stretch flex flex-row items-start justify-center py-0 pr-5 pl-[25px]">
            <h1 className="m-0 w-[252.9px] relative text-inherit font-normal font-inherit inline-block shrink-0 mq750:text-21xl mq450:text-11xl">
              Retail
            </h1>
          </div>
          <div className="self-stretch relative text-xl font-poppins text-darkturquoise mq450:text-base">
            Queue Skipping Solutions
          </div>
        </div>
      </section>
      <FrameComponent1 />
      <FrameComponent />
      <Footer />
    </div>
  );
};

export default ShavistaMain;
