import PropTypes from "prop-types";

const FrameComponent2 = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[100px] box-border max-w-full text-left text-17xl text-black font-prosto-one ${className}`}
    >
      <div className="w-[1209px] flex flex-col items-center justify-center gap-[56px] max-w-full mq750:gap-[28px]">
        <h2 className="m-0 self-stretch relative text-inherit font-normal font-inherit mq750:text-10xl mq450:text-3xl">
          Our Expertise
        </h2>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[90px_28px] max-w-full text-7xl font-poppins">
          <div className="flex-1 flex flex-col items-center justify-start gap-[6px] min-w-[153px] max-w-full">
            <div className="relative font-medium mq450:text-2xl">
              Full-Stack Web Development
            </div>
            <div className="self-stretch relative text-base text-dimgray text-justify">
              Full user facing and back-end systems with database integration.
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-start gap-[6px] min-w-[153px] max-w-full">
            <div className="self-stretch relative font-medium inline-block overflow-hidden text-ellipsis whitespace-nowrap min-h-[35px] max-h-[75px] mq450:text-2xl">
              Web design
            </div>
            <div className="self-stretch relative text-base text-dimgray text-justify">
              Designing websites with an ultra smooth user experience in mind.
            </div>
          </div>
          <div className="flex-1 overflow-hidden flex flex-col items-center justify-start gap-[6px] min-w-[153px] max-w-full">
            <div className="self-stretch relative font-medium mq450:text-2xl">
              Mobile Applications
            </div>
            <div className="self-stretch relative text-base text-dimgray text-justify">
              Solutions for mobiles, with ultra smooth user experience and
              intelligently designed back-end systems.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
