import MyDesignPreview from "./MyDesignPreview";
import PropTypes from "prop-types";

const FrameComponent = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-start pt-0 pb-[53.5px] pr-[54px] pl-[50px] box-border max-w-full text-left text-17xl text-black font-poppins mq450:pb-[23px] mq450:box-border mq1050:pl-[25px] mq1050:pr-[27px] mq1050:pb-[35px] mq1050:box-border ${className}`}
    >
      <div className="flex-1 flex flex-col items-start justify-start gap-[45px] max-w-full mq750:gap-[22px]">
        <div className="self-stretch flex flex-row items-start justify-center py-0 pr-6 pl-5 font-prosto-one">
          <h2 className="m-0 h-11 relative text-inherit font-normal font-inherit inline-block mq750:text-10xl mq450:text-3xl">
            Minimalist and Design
          </h2>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-between py-1 pr-3.5 pl-0 gap-[20px] text-6xl">
          <div className="relative font-semibold text-salmon inline-block min-w-[32px] mq450:text-xl">
            All
          </div>
          <div className="relative mq450:text-xl">Mobile Design</div>
          <div className="relative mq450:text-xl">Web Design</div>
          <div className="relative mq450:text-xl">Motion Design</div>
          <div className="relative mq450:text-xl">Illustration Design</div>
        </div>
        <div className="self-stretch h-0 flex flex-row items-start justify-start pt-0 px-0 pb-0 box-border max-w-full">
          <img
            className="h-0.5 w-[38px] relative"
            loading="lazy"
            alt=""
            src="/vector-2519.svg"
          />
          <img
            className="flex-1 relative max-w-[calc(100%_-_38px)] overflow-clip max-h-[unset]"
            loading="lazy"
            alt=""
            src="/vector-2520.svg"
          />
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 px-[236px] box-border gap-[60px_57.9px] max-w-full text-xl mq750:gap-[29px] mq750:pl-[59px] mq750:pr-[59px] mq750:box-border mq450:pl-5 mq450:pr-5 mq450:box-border mq1225:pl-[118px] mq1225:pr-[118px] mq1225:box-border">
          <MyDesignPreview
            imageBackgrounds="/rectangle-18223@2x.png"
            mobileDesign="Web Design"
            profilePictures="/ellipse-738@2x.png"
          />
          <MyDesignPreview
            imageBackgrounds="/rectangle-18223-1@2x.png"
            mobileDesign="Mobile Design"
            profilePictures="/ellipse-738-1@2x.png"
          />
          <MyDesignPreview
            imageBackgrounds="/rectangle-18223-2@2x.png"
            mobileDesign="Deshboard Design"
            profilePictures="/ellipse-738-2@2x.png"
          />
        </div>
      </div>
    </section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
