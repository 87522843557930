import React from 'react';
import PropTypes from 'prop-types';

const Navigation1 = ({ className = "" }) => {
  return (
    <header
      className={`self-stretch bg-white flex flex-row items-start justify-start top-[0] z-[99] sticky max-w-full text-left text-11xl text-darkslategray font-poppins ${className}`}
    >
      <div className="flex-1 flex flex-row items-start justify-between py-[36.5px] px-20 box-border gap-[20px] max-w-full mq1050:pl-10 mq1050:pr-10 mq1050:box-border">
        <div className="flex flex-row items-start justify-start gap-[16px] max-w-full">
          <div className="flex flex-col items-start justify-start pt-[3px] px-0 pb-0">
            <h3 className="m-0 relative text-inherit font-semibold font-inherit whitespace-nowrap">
              Shavista Infotech
            </h3>
          </div>
          <img
            className="self-stretch w-[58px] relative max-h-full object-cover min-h-[48px]"
            loading="lazy"
            alt=""
            src="/logo-1@2x.png"
          />
        </div>
        <nav className="m-0 w-[471px] flex flex-col items-start justify-start pt-[9px] px-0 pb-0 box-border max-w-full mq1050:hidden">
          <nav className="m-0 self-stretch flex flex-row items-start justify-between gap-[20px] text-left text-xl text-black font-poppins">
            <div className="relative inline-block min-w-[90px] whitespace-nowrap">
              About Us
            </div>
            <div className="relative inline-block min-w-[92px] whitespace-nowrap">
              Our Work
            </div>
            <div className="relative inline-block min-w-[111px] whitespace-nowrap">
              Contact Us
            </div>
          </nav>
        </nav>
        <div className="flex flex-col items-start justify-start pt-3.5 px-0 pb-0">
          <div className="w-12 h-5 relative">
            <div className="absolute top-[0px] right-[0px] bg-black w-[30.8px] h-1" />
            <div className="absolute top-[16px] right-[17.2px] bg-black w-[30.8px] h-1" />
          </div>
        </div>
      </div>
    </header>
  );
};

Navigation1.propTypes = {
  className: PropTypes.string,
};

export default Navigation1;
